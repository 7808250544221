import { AnchorHTMLAttributes, DetailedHTMLProps, useState } from 'react';
import { toast } from 'react-toastify';
import useCopyToClipboard from '@hooks/useCopyToClipboard';
import { StyledAtSign, StyledEmailLink, CopyIcon } from '../Link.styles';

export interface EmailLinkProps
  extends DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  email: URL;
}

const reverseString = (input: string) => input.split('').reverse().join('');

const EmailLink = ({ email, ...props }: Readonly<EmailLinkProps>) => {
  const { copy } = useCopyToClipboard();
  const [hovered, setHovered] = useState(false);
  const emailAddress = email.pathname;
  const [username, domain] = emailAddress.split('@');
  const hasSearch = email.search.length > 0;
  const emailUrl = new URL(`mailto:${emailAddress}`);

  if (hasSearch) {
    emailUrl.search = email.search;
  }

  const onMouseOver = () => {
    setHovered(true);
  };

  const onClick = () => {
    toast.success('Content was copied to clipboard!');
    copy(emailAddress);
  };

  return (
    <>
      <StyledEmailLink
        href={hovered ? emailUrl.href : '#'}
        onFocus={onMouseOver}
        onMouseOver={onMouseOver}
        className="email-link"
        {...props}
      >
        {reverseString(domain)}
        <StyledAtSign />
        {reverseString(username)}
      </StyledEmailLink>
      <button title="Copy to clipboard" onClick={onClick}>
        <CopyIcon />
      </button>
    </>
  );
};

export default EmailLink;
