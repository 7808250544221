import { toast } from 'react-toastify';
import useCopyToClipboard from '@hooks/useCopyToClipboard';
import { StyledCopyButton, StyledCopySvg } from '../CodeSnippet.styles';

const CopyButton = ({ value }: { value: string }) => {
  const { copy } = useCopyToClipboard();
  const onClick = () => {
    toast.success('Content was copied to clipboard!');
    copy(value);
  };

  return (
    <StyledCopyButton title="Copy to clipboard" onClick={onClick}>
      <StyledCopySvg />
    </StyledCopyButton>
  );
};

export default CopyButton;
