import { DOMNode, Element, domToReact } from 'html-react-parser';
import getTextFromElement from '@utils/getTextFromElement';
import CustomLink from '@components/Link/Link';
import ExternalLink from '@components/Link/components/ExternalLink';
import InternalLink from '@components/Link/components/InternalLink';
import useGlobalData from '@hooks/useGlobalData';
import { InlineImage } from './RichTextLink.styles';
import EmailLink from '@components/Link/components/EmailLink';

export const RichTextLink = ({
  domNode,
  link,
}: {
  domNode: Element;
  link?: Queries.kontent_item_rich_text_element_link;
}): JSX.Element | null => {
  const { children = [], attribs = {} } = domNode;
  const { customLinks } = useGlobalData();
  if (!children.length || (!attribs.href && !attribs['data-item-id'])) {
    return null;
  }

  // NOTE: Casting to DOMNode[] is necessary since v5, potential ETA for a fix unknown
  // SOURCE: https://www.npmjs.com/package/html-react-parser#migration
  const linkInnerElements = domToReact(domNode.children as DOMNode[], {
    trim: true,
  });
  const linkInnerText = getTextFromElement(linkInnerElements);
  const pageId = attribs['data-item-id'];
  const href = attribs.href;
  const customLinkElements =
    pageId &&
    link?.type === 'link' &&
    customLinks.find((link) => link.system.id === pageId)?.elements;

  if (/(png|webp|jpg|svg)$/.test(href)) {
    return <InlineImage src={href} alt={linkInnerText} />;
  }
  if (customLinkElements) {
    return (
      <CustomLink
        __typename="kontent_item_link"
        system={{ id: pageId }}
        elements={{
          title: {
            value: linkInnerText,
          },
          action: {
            value: customLinkElements.action.value,
          },
        }}
        className={
          customLinkElements.action.value.__typename === 'kontent_item_link_action_internal'
            ? 'internal-link'
            : 'external-link'
        }
      />
    );
  }

  if (pageId) {
    return (
      <InternalLink
        title={linkInnerText}
        className="internal-link"
        __typename="kontent_item_link_action_internal"
        elements={{
          page: {
            value: {
              system: {
                id: pageId,
              },
            },
          },
          hash: {
            value: null,
          },
          query: {
            value: null,
          },
        }}
      >
        {linkInnerElements}
      </InternalLink>
    );
  }

  if (href.startsWith('mailto:')) {
    return <EmailLink email={new URL(href)}>{linkInnerElements}</EmailLink>;
  }

  return (
    <ExternalLink
      title={linkInnerText}
      __typename="kontent_item_link_action_external"
      elements={{
        external_url: {
          value: href,
        },
      }}
      className="external-link"
    >
      {linkInnerElements}
    </ExternalLink>
  );
};
