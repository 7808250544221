import tw, { styled } from 'twin.macro';
import CopySvg from '../../../static/images/copy.svg';

export const StyledPre = styled.pre`
  ${tw`relative`}
`;

export const StyledCode = styled.code`
  ${tw`overflow-x-auto`}
  ${tw`w-[calc(100%-36px)]`}
`;

export const StyledCopyButton = styled.button`
  ${tw`absolute top-0 right-0`}
  ${tw`p-[18px]`}
  &:hover > svg {
    ${tw`text-primary-purple`}
  }
`;

export const StyledCopySvg = styled(CopySvg)`
  ${tw`w-[24px] h-[24px]`}
  ${tw`text-neutrals-n7`}
`;
