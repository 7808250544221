import tw, { styled } from 'twin.macro';
import CopySvg from '../../../static/images/copy.svg';

export const ExternalLinkIcon = styled.svg`
  ${tw`inline-block ml-2`}
`;

export const StyledEmailLink = styled.a`
  unicode-bidi: bidi-override;
  direction: rtl;
`;

export const StyledAtSign = styled.span`
  ${tw`after:content-['@']`}
`;

export const CopyIcon = styled(CopySvg)`
  ${tw`h-[16px]`}
  ${tw`pt-[3px]`}
`;
